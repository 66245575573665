import { classNames } from "lib/generic-utils";
import { Spinner, SpinnerProps } from "flowbite-react";

type StyledSpinnerProps = {
    className?:string;
} & SpinnerProps;

export default function StyledSpinner({className, ...rest }: StyledSpinnerProps) {
  return <Spinner 
    className={classNames('fill-black-400 text-neutral-200 fill-black dark:text-neutral-600 dark:fill-white animate-spin', className || '')}
    {...rest} />;
}
