import { useState, useRef, useCallback, useEffect } from "react";
import StyledSpinner from "./StyledSpinner";

interface HoverVideoPlayerProps {
    imageUrl: string;
    videoUrl?: string;
    onPlayback?: (isPlaying: boolean) => void;
    isAudioReactive?: boolean;
    muted?: boolean;
}

export const HoverVideoPlayer = ({
    videoUrl,
    imageUrl,
    onPlayback,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isAudioReactive,
    muted,
}: HoverVideoPlayerProps) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);

    const isMobile = () => window.innerWidth < 640;

    const checkIfVideoCentered = useCallback(() => {
        if (!containerRef.current || !isMobile()) return;
        const containerRect = containerRef.current.getBoundingClientRect();
        const containerCenter = containerRect.top + containerRect.height / 2;
        const screenCenter = window.innerHeight / 2;
        const isCentered = Math.abs(screenCenter - containerCenter) < containerRect.height / 2;
        setIsPlaying(isCentered);
    }, []);

    useEffect(() => {
        if (isMobile()) {
            window.addEventListener('scroll', checkIfVideoCentered);
            checkIfVideoCentered();
        }
        return () => {
            if (isMobile()) {
                window.removeEventListener('scroll', checkIfVideoCentered);
            }
        };
    }, [checkIfVideoCentered]);

    useEffect(() => {
        const video = videoRef.current;
        if (isPlaying && video) {
            setIsLoading(true);
            void video.play().then(() => {
                setIsLoading(false);
                onPlayback?.(true);
            }).catch(() => {
                setIsLoading(false);
            });
        } else {
            video && video.pause();
            onPlayback?.(false);
        }
    }, [isPlaying, onPlayback]);

    const handleMouseEnter = () => {
        if (!isMobile()) setIsPlaying(true);
    };

    const handleMouseLeave = () => {
        if (!isMobile()) setIsPlaying(false);
    };

    return (
        <div
            ref={containerRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="w-full h-full bg-no-repeat bg-center relative"
            style={{ backgroundSize: 'cover', backgroundImage: `${imageUrl}` }}
        >
            {isPlaying && (
                <>
                    {isLoading && (
                        <div className="absolute inset-0 flex items-center justify-center">
                            <StyledSpinner />
                        </div>
                    )}
                    <video
                        ref={videoRef}
                        src={videoUrl}
                        loop
                        muted={muted}
                        className="w-full h-full object-cover"
                        onLoadedData={() => setIsLoading(false)}
                    />
                </>
            )}
        </div>
    );
};