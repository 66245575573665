import { S3_BUCKET_NAME } from '@/lib/config';
import { AnimationDisplayData, AnimationDisplayDataAdminOnlyInfo,  } from 'app/animate/[[...id]]/animate-utils';
import { getLatestDisplayDataForJob } from "app/browser/[id]/ServerActions";
import { Dimensions } from "app/types";
import { Progress } from "flowbite-react";
import { statusToOverlay } from "lib/client-utils";
import { isCompleteStatus } from 'lib/clientsafe-utils';
import { classNames } from "lib/generic-utils";
import { isNumber } from "lodash";
import { DateTime } from "luxon";
import Link from "next/link";
import { useCallback, useEffect, useState } from "react";
import { PiSpeakerSimpleHigh, PiSpeakerX, PiLockKey } from "react-icons/pi";
import useSWR from "swr";
import { HoverVideoPlayer } from "./HoverVideoPlayer";
import { calculateProgress, getBestVideoUrl, getFinalVideo, getFrameUrl, getLatestPreviewVideo } from 'app/animate/[[...id]]/animate-utils-client-safe';

type JobCardProps = {
    displayData: AnimationDisplayData,
    adminOnlyData?: AnimationDisplayDataAdminOnlyInfo,
    showArtist: boolean,
    showTitle: boolean,
    showStatus: boolean,
    showCreateDate: boolean,
    liveUpdate?: boolean,
    className?: string,
    useAspectRatio?: boolean,
}

function getThumbSize(dims : Dimensions) {
    if (dims.height == dims.width) {
        //square
        return { aspectRatio: 'aspect-square', thumbWidth: 'w-[350px] md:w-[250px] 2xl:w-[350px]', thumbHeight: 'h-[350px] md:h-[250px] 2xl:h-[350px]' };
    } else if (dims.width > dims.height) {
        // landscape
            return { aspectRatio: 'aspect-video', thumbWidth: 'w-[350px] md:w-[445px] 2xl:w-[622px]', thumbHeight: 'h-[196px] md:h-[250px] 2xl:h-[350px]' };
    } else {
        // portrait
            return { aspectRatio: 'aspect-[9/16]', thumbWidth: 'w-[350px] md:w-[140px] 2xl:w-[196px]', thumbHeight: 'h-[622px] md:h-[250px] 2xl:h-[350px]' };
    }
}

const JobCard = ({
    displayData,
    adminOnlyData,
    showArtist,
    showTitle,
    showStatus,
    showCreateDate,
    liveUpdate,
    className,
    useAspectRatio
}: JobCardProps) => {
    const [loading, setLoading] = useState(false);
    const jobAuthor = displayData.artistName;
    const display_name = displayData.displayName;
    const { thumbWidth, thumbHeight, aspectRatio } = getThumbSize({width: displayData.width , height: displayData.height});
    const [muted, setMuted] = useState(true);

    const [hideOverlays, setHideOverlays] = useState(false);

    const [imageUrls, setImageUrls] = useState<string>(`url(${getFrameUrl(displayData.id, displayData.readyFrames, displayData.framePattern)}), url(https://deforum.download/jobs/${displayData.id}/frames/000000001.jpg), url(https://${S3_BUCKET_NAME}.s3.amazonaws.com/jobs/${displayData.id}/frames/000000000.jpg), url(/Blur-sq.png)`);
    const [videoUrl, setVideoUrl] = useState<string>(getBestVideoUrl(displayData.videos, displayData.videoBase)||`https://deforum.download/jobs/${displayData.id}/deforum-output.mp4`);
    const [progress, setProgress] = useState<number|undefined>(undefined);
    const [status, setStatus] = useState<string>(displayData.status);

    const isAudioReactive = displayData.isAudioReactive;

    const fetcher = useCallback((id: string) => {
        return getLatestDisplayDataForJob(id);
    }, []);

    const { data } = useSWR((liveUpdate && !isCompleteStatus(status)) ? displayData.id : null, fetcher, {
        revalidateOnFocus: true,
        revalidateOnReconnect: false,
        refreshInterval: 10000
    });


    useEffect(() => {
        if (!data) {
            return;
        }

        //@ts-expect-error - type check
        if (!data.id) {
            console.info("SWR refresh of job failed with: ", data)
            return;
        }

        const displayData = data as AnimationDisplayData;
        const videoPath = getFinalVideo(displayData.videos) || getLatestPreviewVideo(displayData.videos);
        const newVideoUrl = videoPath ? `${displayData.videoBase}${videoPath}` : undefined;
        if (newVideoUrl) {
            setVideoUrl(newVideoUrl);
        }

        const newImageUrl = displayData.readyFrames > 0 ? getFrameUrl(displayData.id, 0, displayData.framePattern) : undefined
        if (newImageUrl) {
            setImageUrls(`url(${newImageUrl})`);
        }

        setProgress((prevProgress) => calculateProgress(displayData, prevProgress));
        setStatus(displayData.status);

    }, [data]);


    const jobStatusOverlay = statusToOverlay(status);

    const privateOverlay = (adminOnlyData && !adminOnlyData.isPublic) ? <div className='text-center' ><PiLockKey className='w-8 h-8 border-white border-2 border-solid bg-red-500 text-white p-1 opacity-80' /></div> : <></>;

    return (
        <Link href={`/animate/${displayData.id}`} passHref>

            <div
                onClick={() => {
                    if (!window.location.pathname.includes(displayData.id)) {
                        setLoading(true);
                    }
                }}
                key={displayData.id}
                className={classNames(`relative bg-cover bg-center m-1 mr-2 ring-0 shadow-none border-transparent ease-in hover:ease-linear hover:duration-300 hover:scale-105 mx-auto`,
                    useAspectRatio ? aspectRatio : `${thumbWidth} ${thumbHeight}`,
                    className || ''
                )}>
                <HoverVideoPlayer
                    imageUrl={imageUrls}
                    videoUrl={videoUrl}
                    onPlayback={(isPlaying) => setHideOverlays(isPlaying)}
                    isAudioReactive={isAudioReactive}
                    muted={muted}
                />
                {loading && <div className="absolute inset-0 bg-black bg-opacity-70 flex flex-col justify-center items-center">
                    <div className="text-white text-2xl">Loading...</div>
                </div>

                }
                {hideOverlays && (videoUrl?.includes("preview"))
                    ? <div className="absolute top-0 right-0 bg-black bg-opacity-70 p-2 text-right text-white">
                        Preview
                    </div>
                    : <></>}
                {!hideOverlays && (showArtist || showTitle)
                    ? <div className="absolute bottom-0  bg-black bg-opacity-70 p-2 text-right text-white">
                        {showTitle && <span className="text-xs">{display_name} </span>}
                        {showArtist && <span className="text-md">by {jobAuthor}</span>}
                    </div>
                    : <></>}
                {!hideOverlays && showStatus
                    ? <div className="absolute top-0 right-0 text-right font-thin text-xs">
                        {jobStatusOverlay}
                    </div>
                    : <></>}
                {!hideOverlays
                    ? <div className="absolute top-10 right-0 text-center">
                        {privateOverlay}
                    </div>
                    : <></>}
                {liveUpdate && !isCompleteStatus(displayData.status) && isNumber(progress) && <Progress className="rounded-none" size="xl" color="dark" labelProgress textLabelPosition="outside" progress={Math.round(progress)} />}
                {!hideOverlays && showCreateDate && isCompleteStatus(displayData.status) && <div className="text-sm text-center">
                    {DateTime.fromJSDate(displayData.createdAt).toRelative()}
                </div>}

                {isAudioReactive
                    ? <div
                        onClick={(e) => {
                            if (e.cancelable) e.preventDefault();
                            e.stopPropagation();
                            setMuted(!muted)
                        }}
                        className="absolute bottom-0 right-0 bg-black bg-opacity-70 p-2 text-right text-white">
                        {muted ? <PiSpeakerX size={24} /> : <PiSpeakerSimpleHigh size={24} />}
                    </div>
                    : <></>}
            </div>
        </Link>
    );
}

export default JobCard;