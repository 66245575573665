// All utlitity functions here (and their imports) must be safe to use both client and server side.
import { isCompleteStatus } from "@/lib/clientsafe-utils";
import { AUDIO_JOB_COST_CREDITS_PER_S, DEFAULT_JOB_COST_CREDITS } from "@/lib/config";
import { PaidAnimationOptions } from "app/types";
import _ from "lodash";
import { AnimationDisplayData } from "./animate-utils";
import { DeforumSettings } from "models/jobRequest";


export function getFrameUrl(jobId: string|undefined, frame: number|undefined, pattern: string|undefined) {
    if (!jobId || !frame || !pattern) {
        return undefined;
    }
    return pattern
        .replace('{{JOBID}}', jobId)
        .replace('{{FRAME_NUMBER:09}}', frame.toString().padStart(9, '0'));
}

export function getBestVideoUrl(videos: string[], baseUrl: string) {
    if (videos.length < 1) {
        return undefined;
    }
    const videoPath = getFinalVideo(videos) || getLatestPreviewVideo(videos);
    return videoPath ? `${baseUrl}${videoPath}` : undefined;
}

export const getFinalVideo = (videos: string[]): string | undefined => {
    if (!videos) {
        return undefined;
    }
    return videos.filter((name) => name.endsWith('mp4') || name.endsWith('webm')) .find((name) => name.includes("deforum-output.") && !name.includes("preview"));
};

export const getLatestPreviewVideo = (videos: string[]): string | undefined => {
    const previews = videos.filter((name) => name && name.includes("preview")).sort().reverse();
    return previews[0];
};

export const getFrames = (jobOutput: { paths: string[]; }): string[] => {
    return jobOutput.paths.filter((path) => path
        && (path.endsWith('jpg') || path.endsWith('png'))
        && (!path.includes('/depth_') && !path.includes('/rgb_'))
        && !path.includes('thumbnail'))
        .sort();
};

export function calculateProgress(displayData : AnimationDisplayData, previousProgress:number=0) : number {
    if (isCompleteStatus(displayData.status)) {
        return 100;
    }

    const isAnimateDiff = displayData.isConsistencyBoosted;

    // How we split the progress phases (out of 100)
    const postProcessingPortion = 10;
    const animateDiffPortion = isAnimateDiff ? 30 : 0;
    const deforumPortion = 100 - postProcessingPortion - animateDiffPortion;
    
    // Deforum progress is based on number of frames generated
    const deforumProgress = (displayData.readyFrames / displayData.expectedFrameCount) * deforumPortion;
    
    // Adiff progress is based on value directly retrieved from server
    const animateDiffProgress = (displayData.aDiffProgress || 0) * animateDiffPortion;
    
    // We don't have much insight into post-processing progress currently
    const postProcessingProgress = 0;

    // Fudge factor: 5% progress per 2 minutes of no updates
    const secondSinceLastUpdate = (Date.now() - new Date(displayData.updatedateAt).getTime()) / 1000;
    const fudgeFactor = _.clamp(secondSinceLastUpdate/120 * 5, 0, 5); 

    // Sum up the progress portions
    const progress =_.floor(deforumProgress + animateDiffProgress + postProcessingProgress + fudgeFactor, 1)

    return _.clamp(progress, previousProgress, 99);
}

export function calculateCost(options : PaidAnimationOptions) {
    
    let cost = options.isAudio ?  options.audioDuration * AUDIO_JOB_COST_CREDITS_PER_S : DEFAULT_JOB_COST_CREDITS;

    if (!options.isAudio) {
        if (options.duration30s) {
            cost += 1;
        }
        if (options.slowMo_v1) {
            cost += options.duration30s ? 0.4 : 0.2;
        }
    }

    if (options.smoothing > 0) {
        cost += 1;
    } 
    
    if (options.imagePrompt) {
        cost += 0.2;
    }



    return cost;
}

export function detectRequiredAudioFields(settings:Partial<DeforumSettings>) {
    const candidateAudioVariables = ['event', 'data_amp', 'data_pan'];
    const foundAudioVariables = new Set<string>();
    for (const [, value] of Object.entries(settings)) {
        const stringValue = JSON.stringify(value)
        for (const candidate of candidateAudioVariables) {
            if (stringValue.includes(candidate)) {
                foundAudioVariables.add(candidate);
            }
        }
    }
    return Array.from(foundAudioVariables);
}