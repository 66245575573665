export const DEFAULT_JOB_COST_CREDITS = 1;
export const AUDIO_JOB_COST_CREDITS_PER_S = 0.1;
export const STALE_JOB_TIMEOUT_MINUTES = 30;
export const DEFAULT_MAX_ACTIVE_JOBS_PER_USER = 3;
export const AUTO_BUY_AMOUNT = 100;


// The clientside check will reject any audio outside of this range.
export const MIN_AUDIO_DURATION = 5;
export const MAX_AUDIO_DURATION = 20;

// Users with a special role will be able to push up to this duration.
// Also, the serverside check will reject any audio outside of the STRICT range.
export const MAX_AUDIO_DURATION_STRICT = 60;

export const DEFAULT_MODEL = 'protovisionxl.safetensors';
export const S3_BUCKET_NAME = "deforum-webapp-output";
export const TEMP_AUDIO_UPLOAD_PREFIX = 'inputAudio/tempUpload/';
export const MILKDROP_PRESET_KEY_PREFIX = 'milkdropPresets/';
export const REPLICATE_COST_PER_S = 0.000575;
export const RUNPOD_COST_PER_S = 0.00044;
export const INITIAL_CREDIT_BALANCE = 0;
export const BASEURL = `${process.env.NEXT_PUBLIC_PROTOCOL}://${process.env.NEXT_PUBLIC_HOSTNAME}`;
export const COMPLETE_STATUSES = ['canceled', 'failed', 'succeeded'];
export const ALL_STATUSES = ['canceled', 'failed', 'succeeded', 'starting', 'pending', 'processing', 'submitted'];

export const BACKEND_P_ATK = parseFloat(process.env.BACKEND_P_ATK||'1');

export const P_NSFW_FRAME_CHECK = parseFloat(process.env.P_NSFW_FRAME_CHECK||'0.01');

export const NSFW_CHECK_TIMEOUT = 10000;

export const BOT_USER_DETAILS = {
    id: "discord-bot-6cd2f93d-e28e-4639-8968-2369b37eaf04",
    name: "Discord bot",
    email: "bot@bot.bot"
};
