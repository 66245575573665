// All functions and imports here must be safe for both client and server use.


import { Job } from "@prisma/client";
import { ALL_STATUSES, COMPLETE_STATUSES, REPLICATE_COST_PER_S, RUNPOD_COST_PER_S } from './config';
import { RunpodJob } from "./gpu/GpuFarm";
import { ReplicateTimings } from "./gpu/ReplicateGpuFarm";

// TODO - make this polymorphic rather than branched.
export const getEstimatedCost = (job: Job) => {

    let waitTime, paidWaitTime, genTime, inprogress, costPerS;
    if (job.externalId?.startsWith("runpod::")) {
        const timings = job.externalData as RunpodJob;
        costPerS = RUNPOD_COST_PER_S;

        waitTime = timings?.delayTime ? timings.delayTime / 1000 : 0;
        paidWaitTime = 0;
        genTime = timings?.executionTime ? timings.executionTime / 1000 : getElapsedTimeMs(job.createdAt, new Date()) / 1000;

        inprogress = !timings?.executionTime;

    } else {
        const timings = job.externalData as ReplicateTimings;
        costPerS = REPLICATE_COST_PER_S;        

        if (!timings
            || !timings.created_at
            || !timings.started_at
        ) {
            return {
                formula: "generation not started",
                cost: 0,
                isInProgress: true,
                started: false
            };
        }
        waitTime = getElapsedTimeMs(timings.created_at, timings.started_at) / 1000;
        paidWaitTime = waitTime;
        genTime = timings.completed_at
            ? getElapsedTimeMs(timings.started_at, timings.completed_at) / 1000
            : getElapsedTimeMs(timings.started_at, new Date()) / 1000;

        inprogress = !timings.completed_at;
    }
    return {
        waitTime,
        genTime,
        formula: `(${paidWaitTime} + ${genTime}) * ${costPerS}`,
        cost: (paidWaitTime + genTime) * costPerS,
        isInProgress: inprogress,
        started: true
    };
}

export function getElapsedTime(startDateS: Date | string, endDateS: Date | string): string {
    const milliseconds = getElapsedTimeMs(startDateS, endDateS);
    const seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    //const days = Math.floor(hours / 24);
    return `${hours % 24}h, ${minutes % 60}min, ${seconds % 60}s`;
}

export function getElapsedTimeMs(startDateS: Date | string, endDateS: Date | string): number {
    const startDate = (typeof startDateS === 'string') ? new Date(startDateS) : startDateS;
    const endDate = (typeof endDateS === 'string') ? new Date(endDateS) : endDateS;
    const milliseconds = endDate.getTime() - startDate.getTime();
    return milliseconds;
}

export function secondsToInterval(inSeconds: number): string {

    const milliseconds = inSeconds * 1000;
    const seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    //const days = Math.floor(hours / 24);
    return `${hours % 24}h, ${minutes % 60}min, ${seconds % 60}s`;
}

export function isCompleteStatus(status: string) {
    return COMPLETE_STATUSES.includes(status);
}

export function isAllStatuses(statusList: string[]|undefined) {
    return ALL_STATUSES.every(status => statusList?.includes(status));
}



export function formatDate(dateOrString: Date | string): string {
    const date = (typeof dateOrString === 'string') ? new Date(dateOrString) : dateOrString;

    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    };

    return new Intl.DateTimeFormat('en-US', options).format(date);
}
