
import _ from 'lodash';

export function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}


// Call a promise and if it takes longer than ms, reject it.
export function timeout<T>(ms: number, promise: Promise<T>) : Promise<T|null> {
    const timeout = new Promise<null>((_, reject) => {
        setTimeout(() => {
            reject(new Error("Operation timed out"));
        }, ms);
    });
    return Promise.race([promise, timeout]);
}

export function stringToColor(str: string): string {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash; 
    }

    let color = '#';
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xFF;
        color += ('00' + value.toString(16)).substring(-2);
    }

    return color;
}



function customizer(objValue: any, srcValue: any): any {
    if (srcValue === undefined) {
        return objValue;
    }
}

export function mergeWithDefaults<T>(defaults: Required<T>, overrides: T): Required<T> {
    return _.mergeWith({}, defaults, overrides, customizer)
}


export function withinLastMonth(d: Date|undefined): boolean {
    if (!d) {
        return false;
    }
    const now = new Date();
    const oneMonthAgo = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
    // Adjust for months with different numbers of days
    if (now.getDate() > oneMonthAgo.getDate()) {
        oneMonthAgo.setDate(0); // Set to the last day of the previous month
    }
    now.setHours(0, 0, 0, 0);
    d.setHours(0, 0, 0, 0);
    
    return d >= oneMonthAgo && d <= now;
}