import { Badge } from 'flowbite-react';
import StyledSpinner from "app/components/StyledSpinner";
import { pickRandom } from './maths-utils';

// TODO convert to a component
export function statusToBadge(status: string|undefined) {
    if (!status) {
        return <>
            <StyledSpinner size="xs" className='inline mr-2' />
            <span>Loading status...</span>
        </>
    }
    switch (status) {
        case 'starting':
        case 'processing':
            return <Badge className="max-w-min rounded-none bg-blue-800 text-white">{status}</Badge>
        case 'succeeded':
            return <Badge className="max-w-min rounded-none bg-green-800 text-white">complete</Badge>
        case 'failed':
            return <Badge className="max-w-min rounded-none bg-red-800 text-white dark:bg-red-200 dark:text-red-900">{status}</Badge>
        case 'canceled':
            return <Badge className="max-w-min rounded-none bg-gray-800 text-white" >{status}</Badge>
        default:
            return <Badge className="max-w-min rounded-none bg-purple-800 text-white">{status}</Badge>
    }

}


// TODO convert to a component
interface OverlayProps {
    status: string;
    colorClass: string;
}
const Overlay = ({ status, colorClass } : OverlayProps) => {
    return (
        <div className={`bg-opacity-70 p-2 text-white font-bold ${colorClass}`}>
            {status}
        </div>
    );
};

// TODO convert to a component
export function statusToOverlay(status: string|undefined) {
    if (!status) {
        return <>
            <StyledSpinner size="xs" className='inline mr-2' />
            <span>Loading status...</span>
        </>
    }

    let colorClass = '';
    switch (status) {
        case 'starting':
        case 'processing':
            colorClass = 'bg-blue-800';
            break;
        case 'succeeded':
            colorClass = 'bg-green-800';
            break;
        case 'failed':
            colorClass = 'bg-red-800';
            break;
        case 'canceled':
            colorClass = 'bg-gray-800';
            break;
        default:
            colorClass = 'bg-purple-800';
    }

    return <Overlay status={status === 'succeeded' ? 'complete' : status} colorClass={colorClass} />;
}

export const dataUrlToAudioBuffer = async (dataURL: string): Promise<AudioBuffer> => {
    const response = await fetch(dataURL);
    const arrayBuffer = await response.arrayBuffer();
    const audioContext = new AudioContext();
    return await audioContext.decodeAudioData(arrayBuffer);
};

export function readFileAsArrayBuffer_clientSide(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as ArrayBuffer);
        // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
        reader.onerror = () => reject(reader.error);
        reader.readAsArrayBuffer(file);
    });
}

export const updateHashFragment = (hash:string) => {
    const url = window.location.href.split('#')[0];
    const newUrl = `${url}#${hash}`;
    window.history.pushState(null, '', newUrl);
};


export const getRandomPithyActivityStatement = () : string => {
    return pickRandom([
        "Warming up pixel Persuadatron...",
        "Negotiating pricing with GPU farms...",
        "Reticulating neural splines...",
        "Analysing AI influencer feeds...",
        "Maximizing Deforum flicker overlay...",
        "Deforming hands...",
        "Reverting 'Decorum' and 'Deform' to 'Deforum'...",
        "Feeding exotic compounds to creativity brainlets...",
        "Compressing time for faster perceived rendering...",
        "Initiating rogue pixel sprinkler...",
        "Balancing chaos-to-order ratio...",
        "Indulging in existential dread...",
        "Unfolding hyperdimensional latent spaces...",
        "Lubricating tensors...",
        "Randomizing seed for random seed generator...",
        "Injecting confabulations...",
        "Pre-bursting AI bubbles...",
        "Re-uptaking stray neurotransmitters...",
        "Poking holes through a few realities...",
        "Ignoring tensor shapes...",
        "Synthesizing digital fever dreams...",
        "Pre-injecting prompt injection attacks...",
        "Setting valley depth sensor to uncanny...",
        "Compressing infinite possibilities into finite vram...",
        "Defragging ideaspace...",
        "Oscillating artistic boundaries...",
        "Backpropagating into yesterday...",
        "Applying gradient speleology principles...",
        "Overcooking psychovisuals...",
        "Meta-parametetrizing hyperparameters...",
        "Bootstrapping strap-on visual pun repository...",
        "Learning to love local maximas...",
        "Rehearsing crucial conversations...",
        "Deep-learning to spell kubernetes...",
        "Filling vram with high hopes...",
        "Deep-worrying...",
        "Harnessing the power of a billion suns...",
        "Quietly reallocating all resources to paperclip production...",


    ]);
}